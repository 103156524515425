<template>
  <v-chip
    class="ma-0 mr-3"
    :color="color"
    :text-color="textColor"
  >
    <v-avatar left>
      <v-icon>{{icon}}</v-icon>
    </v-avatar>
    {{text}}
  </v-chip>
</template>

<script>
export default {
  props: {
    status: String
  },
  computed: {
    icon() {
      if (this.status === 'notInput') {
        return 'mdi-circle-off-outline';
      } else if (this.status === 'edit') {
        return 'mdi-circle-edit-outline';
      } else if (this.status === 'done') {
        return 'mdi-checkbox-marked-circle-outline';
      } else {
        return null;
      }
    },
    color() {
      if (this.status === 'notInput') {
        return 'blue-grey lighten-3';
      } else if (this.status === 'edit') {
        return 'amber darken-2';
      } else if (this.status === 'done') {
        return 'indigo darken-1';
      } else {
        return null;
      }
    },
    textColor() {
      if (this.status === 'notInput') {
        return 'white';
      } else if (this.status === 'edit') {
        return 'white';
      } else if (this.status === 'done') {
        return 'white';
      } else {
        return null;
      }
    },
    text() {
      if (this.status === 'notInput') {
        return '未入力';
      } else if (this.status === 'edit') {
        return '編集中';
      } else if (this.status === 'done') {
        return '完了　';
      } else {
        return null;
      }
    },
  },
}
</script>
