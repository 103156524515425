<template>
  <v-row class="ma-0">
    <v-col sm="4" md="2" class="pa-0" align="center"><p class="ma-0 mr-3 mb-1">BPシート</p><section-status :status="project.brandStatus"></section-status></v-col>
    <v-col sm="4" md="2" class="pa-0" align="center"><p class="ma-0 mr-3 mb-1">戦略設計</p><section-status :status="project.flameworkStatus"></section-status></v-col>
    <v-col sm="4" md="2" class="pa-0" align="center"><p class="ma-0 mr-3 mb-1">ユーザ理解</p><section-status :status="project.userinsightStatus"></section-status></v-col>
    <v-col sm="4" md="2" class="pa-0" align="center"><p class="ma-0 mr-3 mb-1">デザイン</p><section-status :status="project.designStatus"></section-status></v-col>
    <v-col sm="4" md="2" class="pa-0" align="center"><p class="ma-0 mr-3 mb-1">プランニング</p><section-status :status="project.planningStatus"></section-status></v-col>
    <v-col sm="4" md="2" class="pa-0" align="center"><!--<p class="ma-0 mr-3 mb-1">振り返り</p><section-status :status="project.progress_6"></section-status>--></v-col>
    <v-spacer></v-spacer>
  </v-row>
</template>
<script>
import SectionStatus from './SectionStatus.vue';
export default {
  components: {
    SectionStatus
  },
  data() {
    return {
    }
  },
  props: {
    project: Object,
  }
}
</script>