<template>
  <div class="project-card__wrap">
    <v-card :max-width="width" class="project-card">
      <v-card-text class="pt-1">
        <v-row class="ma-0">
          <v-col
            sm="0"
            md="2"
            class="project-card__l-col pa-0 d-none d-lg-block"
            align-self="center"
          >
            <v-row class="ma-0">
              <div v-if="!$vuetify.breakpoint.mobile" class="ma-0 pa-0">
                <v-col v-if="project.projectIconUrl" class="ml-0">
                  <v-avatar size="130px" class="ma-0 pa-0" tile>
                    <img
                      v-if="project.projectIconUrl"
                      :src="project.projectIconUrl"
                    />
                  </v-avatar>
                </v-col>
                <v-col v-else class="project-card__image-col ma-3"></v-col>
              </div>
            </v-row>
          </v-col>
          <v-col cols="12" lg="10" md="10" class="pt-3 pl-lg-0">
            <v-row class="ma-0 mb-1">
              <v-col lg="10" cols="12" class="pa-0 d-flex order-lg-0">
                <router-link
                  class="pa-0 text-wrap mb-1 button-label btPrimary--text"
                  :to="{
                    name: 'StrategyTopPage',
                    params: { projectId: project.id },
                  }"
                >
                  <span class="text-h5 font-weight-bold">
                    {{ project.projectName }}
                  </span>
                </router-link>
              </v-col>
              <v-col class="py-1 px-0 order-lg-2" lg="10" cols="12">
                <div><span class="text-body">{{ project.clientName }}／{{ project.businessName }}</span></div>
              </v-col>
              <div class="py-1 px-0 d-flex justify-lg-center order-lg-1 mx-lg-auto pr-3 pr-lg-0" lg="2">
                <v-card
                  v-show="project.industry"
                  class="py-1 px-3 text-caption"
                  height="fit-content"
                  elevation="0"
                  outlined
                  rounded="lg"
                  width="fit-content"
                >
                  {{ project.industry }}
                </v-card>
              </div>
              <div v-if="project.archivedAt" class="py-1 px-0 d-flex justify-lg-center order-lg-3 mx-lg-auto" lg="2">
                <v-card
                  class="btAccent--text py-1 px-3 text-caption"
                  height="fit-content"
                  elevation="0"
                  outlined
                  rounded="pill"
                  width="fit-content"
                >
                  アーカイブ済み
                </v-card>
              </div>
            </v-row>
            <v-row class="ma-0 mb-1 mt-4 align-center">
              <div class="me-5">
                <span class="text-caption">ステータス</span>
                <br v-if="$vuetify.breakpoint.mobile" />
                <span v-else class="ml-2"></span>
                <span
                  class="
                    font-weight-bold
                    text-subtitle-1
                  "
                  >{{ projectStatus }}</span
                >
              </div>
              <div class="py-1 me-5" v-if="project.businessPhase">
                <span class="text-caption">事業フェーズ</span>
                <br v-if="$vuetify.breakpoint.mobile" />
                <span v-else class="ml-2"></span>
                <span
                  class="
                    project-card__header-value
                    font-weight-bold
                    text-subtitle-1
                  "
                  >{{ project.businessPhase }}</span
                >
              </div>

              <div sm="4" md="6" class="py-1" v-if="project.issue">
                <span class="text-caption lg-4">課題分類</span>
                <br v-if="$vuetify.breakpoint.mobile" />
                <span v-else class="ml-2"></span>
                <v-chip 
                  v-for="(item, idx) in this.project.issue" 
                  :key="idx"
                  class="white--text me-1"
                  color="btCancel" 
                  small
                >
                {{ item.issue }}
                </v-chip>
              </div>
            </v-row>
            <!-- TODO:戦略情報登録の入力ステータスは仕様未策定のため一旦非表示 -->
            <!-- <project-progress :project="project"></project-progress> -->
          </v-col>
        </v-row>

        <v-row class="project-card__divider ma-0"
          ><v-divider></v-divider
        ></v-row>

        <slot name="action">
          <v-row class="ma-0 px-3 pt-2 justify-space-between" no-gutters>
            <v-col sm="4" md="2" lg="1" class="pb-0 pr-0">
              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="#547cbc"
                    v-bind="attrs"
                    v-on="on"
                    class="white--text"
                    depressed
                    medium
                  >
                    プロジェクトメニュー
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="menu in projectMenu"
                    :key="menu.label"
                    :to="menu.link"
                    :disabled="!menu.link && !menu.action"
                    @click="menu.action"
                  >
                    <v-list-item-title>{{ menu.label }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
            <v-col v-if="!$vuetify.breakpoint.xs" sm="6" md="8" lg="9" class="pb-0 pr-0">
              <div class="d-flex justify-end overflow-x-auto">
                <div class="text-caption mx-2 mb-0"
                  >登録日時：
                  <span class="font-italic">{{ $dayjs(project.createdAt).format("YYYY/MM/DD") }}</span>
                </div>
                <span class="text-caption mx-2 mb-0"
                  >登録者：{{
                    project.createdUserName
                  }}</span
                >
              </div>
              <div class="d-flex justify-end overflow-x-auto">
                <span class="text-caption mx-2 mb-0"
                  >更新日時：
                  <span class="font-italic">{{ $dayjs(project.updatedAt).format("YYYY/MM/DD") }}</span>
                </span>
                <span class="text-caption mx-2 mb-0"
                  >更新者：{{
                    project.updatedUserName
                  }}</span
                >
              </div>
            </v-col>
            <!-- TODO:いいねも仕様未策定のため非表示 -->
            <!--
            <v-col sm="2" md="1" align-self="center" class="pb-0 pl-1 pr-3">
              <div class="d-flex justify-end">
                <v-icon>mdi-thumb-up</v-icon>
                <span
                  class="mx-2 text-body-2 font-weight-bold"
                  style="color: rgb(84, 124, 188)"
                  >{{ project.projectHowNiceCount }}</span
                >
              </div>
            </v-col>
            -->
          </v-row>
        </slot>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import MasterData, { searchById } from "../../../utils/MasterData.js";

import ProjectProgress from "../molecules/ProjectProgress.vue";

export default {
  props: ["value"],
  components: {
    ProjectProgress,
  },
  data() {
    return {
      project: null,

      iconLabel: "image",
      menuValue: 0,

      // プロジェクトメニュー
      projectMenu_base: [
        {
          id: 1,
          projectMenu: "プロジェクト編集",
          link: "strategy/%PID%/project/edit",
          membersOnly: true,
        },
        {
          id: 2,
          projectMenu: "プロジェクト閲覧",
          link: "strategy/%PID%?mode=readonly",
          membersOnly: false,
        },
        {
          id: 3,
          projectMenu: "複製する",
          link: "",
          action: this.onClickCopy,
          membersOnly: false,
        },
      ],
    };
  },
  created() {
    if (this.value) {
      this.project = this.value;
    }
  },
  mounted() {
    console.log(this.$vuetify.breakpoint.name);
  },
  methods: {
    isMember() {
      if (this.userInfo.superUser) return true;

      if (this.project.affiliatedUser.length > 0) {
        for (let i = 0; i < this.project.affiliatedUser.length; i++) {
          if (this.userInfo.userId == this.project.affiliatedUser[i].userId) {
            return true;
          }
        }
      }
      return false;
    },

    onClickCopy() {
      this.$emit('click:copy', { projectId: this.project.id, projectName: this.project.projectName });
    }
  },
  computed: {
    ...mapGetters(["userInfo"]),
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case "lg":
          return 1500;
        case "xl":
          return 1500;
        default:
          return "100%";
      }
    },
    projectStatus() {
      return searchById(MasterData, "statuses", "status", this.project.status);
    },
    projectMenu() {
      const isMember = this.isMember();
      const list = [];
      for (let i = 0; i < this.projectMenu_base.length; i++) {
        if (isMember || this.projectMenu_base[i].membersOnly === false) {
          var label = this.projectMenu_base[i].projectMenu;
          if (
            isMember &&
            this.projectMenu_base[i].hasOwnProperty("projectMenuForMember")
          ) {
            label = this.projectMenu_base[i].projectMenuForMember;
          }
          const data = {
            label: label,
            link: this.projectMenu_base[i].link.replace(
              "%PID%",
              this.project.id
            ),
            action: this.projectMenu_base[i].action ?? function(){},
          };
          list.push(data);
        }
      }
      return list;
    },
  },
  watch: {
    value(val) {
      this.project = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.project-card {
  margin-left: auto;
  margin-right: auto;

  &__wrap {
    width: 100%;
  }

  &__l-col {
    max-width: 35%;
  }

  &__image-col {
    max-width: 130px;
    min-width: 130px;
    max-height: 130px;
    min-height: 130px;
    background-color: #888888;
  }

  &__divider-row {
    width: 100%;
  }

  .v-avatar img {
    object-fit: cover;
  }
  .button-label {
    text-transform: none;
    text-decoration: none;
    :hover {
      text-decoration-line: underline;
    }
  }
}
</style>
