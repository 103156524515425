<template>
  <v-dialog :value="value" @input="$emit('input', $event)" :width="width" v-bind="$attrs">
    <v-card>
      <v-card-title> </v-card-title>
      <v-card-text class="text-subtitle-2" :class="body">
        <div v-if="message" class="msg">
          <span v-html="message"></span>
        </div>
        <div v-else class="msg">
          <slot></slot>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!!noText"
          class="mx-2 mb-3"
          depressed
          text
          min-width="190"
          @click="$emit('clickNo', $event)"
        >
          {{ noText }}
        </v-btn>
        <v-btn
          color="btAccent"
          class="white--text mx-2 mb-3"
          depressed
          min-width="190"
          @click="$emit('clickYes', $event)"
        >
          {{ yesText }}
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    width: { default: 500 },
    value: {},
    message: String,
    noText: String,
    yesText: String,
    center: Boolean,
  },
  computed: {
    body() {
      let cl = "";
      cl = this.center ? "text-center" : "text-left";
      return cl;
    },
  },
};
</script>

<style lang="scss" scoped>
.msg {
  color: rgba(0, 0, 0, 0.87);
}
</style>
