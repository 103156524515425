<template>
  <div class="project-log">
    <v-container class="container">
      <!-- 戻るボタン -->
      <v-row>
        <v-col align="start">
          <v-btn text color="btPrimary" dark @click="$router.back()">
            <v-icon size="32" left>mdi-chevron-left-circle-outline</v-icon>
            <span class="black--text">前のページに戻る</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mb-8">
        <v-col cols="12" class="text-h5 font-weight-medium pb-1" align="center">
          <span>過去ログ</span>
        </v-col>
        <v-col cols="12" class="py-0">
          <hr class="page-title-hr" />
        </v-col>
        <v-col class="d-flex">
          <v-spacer></v-spacer>
        </v-col>
      </v-row>

      <!-- 親プロジェクト -->
      <div class="mb-1 pl-3">進行中のプロジェクト</div>
      <div class="mt-3 mb-10">
        <project-card
          v-if="parentPj"
          :value="parentPj"
        >
          <template #action>
            <v-row class="ma-0 px-3 pt-2 justify-space-between" no-gutters>
              <v-col sm="4" md="2" lg="1" class="pb-0 pr-0">
                <v-btn
                  color="btPrimary"
                  class="white--text"
                  depressed
                  medium
                  :to="{
                        name: 'StrategyTopPage',
                        params: { projectId: project.id },
                        query: getQueryParamProjectReadonly(),
                      }"
                >
                  プロジェクトを見る
                </v-btn>
              </v-col>
              <v-col v-if="!$vuetify.breakpoint.xs" sm="6" md="8" lg="9" class="pb-0 pr-0">
                <div class="d-flex justify-end overflow-x-auto">
                  <div class="text-caption mx-2 mb-0"
                    >登録日時：
                    <span class="font-italic">{{ $dayjs(project.createdAt).format("YYYY/MM/DD") }}</span>
                  </div>
                  <span class="text-caption mx-2 mb-0"
                    >登録者：{{
                      project.createdUserName
                    }}</span
                  >
                </div>
                <div class="d-flex justify-end overflow-x-auto">
                  <span class="text-caption mx-2 mb-0"
                    >更新日時：
                    <span class="font-italic">{{ $dayjs(project.updatedAt).format("YYYY/MM/DD") }}</span>
                  </span>
                  <span class="text-caption mx-2 mb-0"
                    >更新者：{{
                      project.updatedUserName
                    }}</span
                  >
                </div>
              </v-col>
            </v-row>
          </template>
        </project-card>
      </div>

      <div class="mb-1 pl-3">アーカイブリスト</div>
      <!-- プロジェクト一覧 -->
      <div v-if="pjGroup.length" class="mb-8">
        <v-data-table
          :headers="headers"
          :items="pjGroup.filter(item => item.id !== item.parentId)"
          :items-per-page="10"
          :item-class="tbClass"
          sort-by="id"
          no-data-text="アーカイブされたプロジェクトはありません"
          no-results-text="アーカイブされたプロジェクトはありません"
          :footer-props="{
            itemsPerPageText: '',
            itemsPerPageAllText: '全件',
            pageText: '{2}件中、{0}-{1}',
          }"
        >
          <template v-slot:[`item.archivedAt`]="{ item }">
            {{ toDateStr(item.archivedAt) }}
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              v-if="item.id !== project.id"
              color="btPrimary"
              class="white--text"
              x-small
              depressed
              tile
              :to="{
                name: 'StrategyTopPage',
                params: { projectId: item.id },
                query: getQueryParamProjectReadonly(),
              }"
              width="115"
              >プロジェクトを見る</v-btn
            >
            <v-btn
              v-else
              color="btAccent"
              class="white--text"
              x-small
              depressed
              tile
              width="115"
              >閲覧中</v-btn
            >
          </template>
        </v-data-table>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { RepositoryFactory } from "../../../api/RepositoryFactory";

import YesNoDialog from "../atoms/YesNoDialog.vue";
import ProjectCard from '../molecules/ProjectCard.vue';

export default {
  props: ["paramSectionId"],
  components: {
    YesNoDialog,
    ProjectCard
  },
  data() {
    return {
      value: null,
      pjGroup: [],

      headers: [
        {
          text: "プロジェクト名",
          align: "start",
          sortable: true,
          value: "projectName",
        },
        { text: "顧客名", value: "clientName", sortable: false },
        { text: "業界", value: "industry", sortable: false },
        { text: "振り返り実施日", value: "archivedAt", sortable: true },
        {
          text: "",
          value: "actions",
          align: "end",
          sortable: false,
          width: "130px",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["project", "userInfo"]),

    parentPj() {
      if(!this.pjGroup.length) return false;
      return this.pjGroup.filter(pj => pj.parentId === pj.id)[0]
    }
  },
  methods: {
    ...mapActions(["getProject"]),

    async load() {
      let param = { userId: this.userInfo.userId };

      // Ph1のprojectはparentIdを持たないのでその場合は自身のidを設定
      let id = !!this.project.parentId
        ? this.project.parentId
        : this.project.id;
      const ProjectRepository = RepositoryFactory.get("project");

      const response = await ProjectRepository.getGroup(id, param);
      if (response.data.result && response.data.resultCode === "0000") {
        this.pjGroup = response.data.data.projects;
        console.log(this.pjGroup);
      } else {
        this._sendError(response.data.message);
      }
    },

    toDateStr(val) {
      const date = new Date(val);
      var y = date.getFullYear();
      var m = ("00" + (date.getMonth() + 1)).slice(-2);
      var d = ("00" + date.getDate()).slice(-2);
      return y + "/" + m + "/" + d;
    },

    tbClass(item) {
      if (this.project.id == item.id) {
        return "blue lighten-5";
      }
    },
  },
  async created() {
    await this.getProject(this.$route.params.projectId)
    console.log(this.project)
    await this.load();
  },
};
</script>

<style lang="scss" scoped>
.project-log {
  @include scrollAreaX();
  width: 100%;
  letter-spacing: 0.0125em;
  padding: 0 15px;

  .container {
    max-width: 1040px;
  }
}
</style>

<style lang="scss"></style>
