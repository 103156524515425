var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"project-log"},[_c('v-container',{staticClass:"container"},[_c('v-row',[_c('v-col',{attrs:{"align":"start"}},[_c('v-btn',{attrs:{"text":"","color":"btPrimary","dark":""},on:{"click":function($event){return _vm.$router.back()}}},[_c('v-icon',{attrs:{"size":"32","left":""}},[_vm._v("mdi-chevron-left-circle-outline")]),_c('span',{staticClass:"black--text"},[_vm._v("前のページに戻る")])],1)],1)],1),_c('v-row',{staticClass:"mb-8"},[_c('v-col',{staticClass:"text-h5 font-weight-medium pb-1",attrs:{"cols":"12","align":"center"}},[_c('span',[_vm._v("過去ログ")])]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('hr',{staticClass:"page-title-hr"})]),_c('v-col',{staticClass:"d-flex"},[_c('v-spacer')],1)],1),_c('div',{staticClass:"mb-1 pl-3"},[_vm._v("進行中のプロジェクト")]),_c('div',{staticClass:"mt-3 mb-10"},[(_vm.parentPj)?_c('project-card',{attrs:{"value":_vm.parentPj},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('v-row',{staticClass:"ma-0 px-3 pt-2 justify-space-between",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pb-0 pr-0",attrs:{"sm":"4","md":"2","lg":"1"}},[_c('v-btn',{staticClass:"white--text",attrs:{"color":"btPrimary","depressed":"","medium":"","to":{
                      name: 'StrategyTopPage',
                      params: { projectId: _vm.project.id },
                      query: _vm.getQueryParamProjectReadonly(),
                    }}},[_vm._v(" プロジェクトを見る ")])],1),(!_vm.$vuetify.breakpoint.xs)?_c('v-col',{staticClass:"pb-0 pr-0",attrs:{"sm":"6","md":"8","lg":"9"}},[_c('div',{staticClass:"d-flex justify-end overflow-x-auto"},[_c('div',{staticClass:"text-caption mx-2 mb-0"},[_vm._v("登録日時： "),_c('span',{staticClass:"font-italic"},[_vm._v(_vm._s(_vm.$dayjs(_vm.project.createdAt).format("YYYY/MM/DD")))])]),_c('span',{staticClass:"text-caption mx-2 mb-0"},[_vm._v("登録者："+_vm._s(_vm.project.createdUserName))])]),_c('div',{staticClass:"d-flex justify-end overflow-x-auto"},[_c('span',{staticClass:"text-caption mx-2 mb-0"},[_vm._v("更新日時： "),_c('span',{staticClass:"font-italic"},[_vm._v(_vm._s(_vm.$dayjs(_vm.project.updatedAt).format("YYYY/MM/DD")))])]),_c('span',{staticClass:"text-caption mx-2 mb-0"},[_vm._v("更新者："+_vm._s(_vm.project.updatedUserName))])])]):_vm._e()],1)]},proxy:true}],null,false,2010404461)}):_vm._e()],1),_c('div',{staticClass:"mb-1 pl-3"},[_vm._v("アーカイブリスト")]),(_vm.pjGroup.length)?_c('div',{staticClass:"mb-8"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.pjGroup.filter(function (item) { return item.id !== item.parentId; }),"items-per-page":10,"item-class":_vm.tbClass,"sort-by":"id","no-data-text":"アーカイブされたプロジェクトはありません","no-results-text":"アーカイブされたプロジェクトはありません","footer-props":{
          itemsPerPageText: '',
          itemsPerPageAllText: '全件',
          pageText: '{2}件中、{0}-{1}',
        }},scopedSlots:_vm._u([{key:"item.archivedAt",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toDateStr(item.archivedAt))+" ")]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [(item.id !== _vm.project.id)?_c('v-btn',{staticClass:"white--text",attrs:{"color":"btPrimary","x-small":"","depressed":"","tile":"","to":{
              name: 'StrategyTopPage',
              params: { projectId: item.id },
              query: _vm.getQueryParamProjectReadonly(),
            },"width":"115"}},[_vm._v("プロジェクトを見る")]):_c('v-btn',{staticClass:"white--text",attrs:{"color":"btAccent","x-small":"","depressed":"","tile":"","width":"115"}},[_vm._v("閲覧中")])]}}],null,true)})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }